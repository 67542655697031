import React from 'react'
import { bool, func, oneOf, string } from 'prop-types'

import { HelperText, StyledTextArea } from './textArea.style'

const propTypes = {
  'data-testid': string,
  defaultValue: string,
  disabled: bool,
  helperText: string,
  label: string,
  name: string.isRequired,
  onBlur: func,
  onChange: func,
  onFocus: func,
  required: bool,
  variant: oneOf(['default', 'success', 'warning', 'danger', 'loading']),
}

const InputTextArea = React.forwardRef((props, ref) => {
  const {
    'data-testid': dataTestId,
    defaultValue,
    disabled,
    helperText,
    label,
    name,
    onBlur,
    onChange,
    onFocus,
    required,
    variant,
  } = props

  return (
    <>
      <StyledTextArea
        name={name}
        label={label}
        helperText={helperText}
        defaultValue={defaultValue}
        disabled={disabled}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        required={required}
        variant={variant}
        ref={ref}
        data-testid={dataTestId}
      />
      {helperText && (
        <HelperText variant="supportForm" color={variant}>
          {helperText}
        </HelperText>
      )}
    </>
  )
})

InputTextArea.propTypes = propTypes
InputTextArea.displayName = 'InputTextArea'

export default InputTextArea
