import { InputTextArea as CreditasInputTextArea } from '@creditas/form-elements/dist/InputTextArea'
import { Typography } from '@creditas/typography'
import { styled } from '@creditas-ui/system'

const HelperText = styled(Typography)`
  margin: -12px 0 8px 0;
`
const StyledTextArea = styled(CreditasInputTextArea)`
  width: 100%;
`

export { HelperText, StyledTextArea }
